<div
  class="hot-toast-bar-base-container"
  [ngStyle]="containerPositionStyle"
  [ngClass]="'hot-toast-theme-' + toast.theme"
  [style.--hot-toast-scale]="scale"
  [style.--hot-toast-translate-y]="translateY"
>
  <div
    class="hot-toast-bar-base-wrapper"
    [class.expanded]="isExpanded"
    (mouseenter)="handleMouseEnter()"
    (mouseleave)="handleMouseLeave()"
  >
    <div
      class="hot-toast-bar-base"
      #hotToastBarBase
      [ngStyle]="toastBarBaseStylesSignal()"
      [ngClass]="toast.className"
      [style.--hot-toast-animation-state]="isManualClose ? 'running' : 'paused'"
      [style.--hot-toast-exit-animation-state]="isShowingAllToasts ? 'paused' : 'running'"
      [style.--hot-toast-exit-animation-delay]="exitAnimationDelay"
      [attr.aria-live]="toast.ariaLive"
      [attr.role]="toast.role"
    >
      <div class="hot-toast-icon" aria-hidden="true">
        @if (toast.icon !== undefined) { @if (isIconString) {
        <hot-toast-animated-icon [iconTheme]="toast.iconTheme" [icon]="toast.icon"></hot-toast-animated-icon>
        } @else {
        <div>
          <ng-container *dynamicView="toast.icon"></ng-container>
        </div>
        } } @else {
        <hot-toast-indicator [theme]="toast.iconTheme" [type]="toast.type"></hot-toast-indicator>
        }
      </div>

      <div class="hot-toast-message">
        <ng-container *dynamicView="toast.message; context: context; injector: toastComponentInjector"></ng-container>
      </div>

      @if (toast.group?.expandAndCollapsible && toast.group?.children && visibleToasts.length > 0) {
      <button
        (click)="toggleToastGroup()"
        type="button"
        class="hot-toast-group-btn"
        [class.expanded]="isExpanded"
        [attr.aria-label]="isExpanded ? 'Collapse' : 'Expand'"
        [ngStyle]="toast.group.btnStyle"
      ></button>
      } @if (toast.dismissible) {
      <button
        (click)="close()"
        type="button"
        class="hot-toast-close-btn"
        aria-label="Close"
        [ngStyle]="toast.closeStyle"
      ></button>
      }
    </div>

    @if (toast.visible) {
    <div
      role="list"
      class="hot-toast-bar-base-group"
      [ngClass]="toast.group?.className"
      [style.--hot-toast-group-height]="groupHeight + 'px'"
    >
      @for (item of groupChildrenToasts; track item.id) {
      <hot-toast-group-item
        [toast]="item"
        [offset]="calculateOffset(item.id)"
        [toastRef]="toastRef.groupRefs[$index]"
        [toastsAfter]="(item.autoClose ? groupChildrenToasts.length : visibleToasts.length) - 1 - $index"
        [defaultConfig]="defaultConfig"
        [isShowingAllToasts]="isShowingAllToasts"
        (height)="updateHeight($event, item)"
        (beforeClosed)="beforeClosedGroupItem(item)"
        (afterClosed)="afterClosedGroupItem($event)"
      ></hot-toast-group-item>
      }
    </div>
    }
  </div>
</div>

<header mat-dialog-title>
  <span>Configurações</span>
  <s></s>
  <button tabindex="-1" type="button" mat-dialog-close mat-icon-button aria-label="Fechar diálogo">
    <svg-icon src="assets/mdi/close.svg" svgAriaLabel="Ícone de fechar diálogo" />
  </button>
</header>

<mat-dialog-content>
  <div class="preferences">
    <div class="preference-section">
      <div class="preference-section-header">
        <span>Interface</span>
      </div>

      <div class="preference-list">
        <div class="preference">
          <mat-label id="setting-interface-appearance-label">Aparência</mat-label>

          <mat-radio-group
            aria-labelledby="setting-interface-appearance-label"
            name="theme"
            [(ngModel)]="theme"
            class="radio-group"
            color="primary"
          >
            <mat-radio-button value="auto">Automático (sistema)</mat-radio-button>
            <mat-radio-button value="light">Claro</mat-radio-button>
            <mat-radio-button value="dark">Escuro</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="preference-section">
      <div class="preference-section-header">
        <span>Editor</span>
      </div>

      <div class="preference-list">
        <div class="preference">
          <mat-label id="setting-editor-font-size-label">Tamanho da fonte</mat-label>

          <div class="preference-slider">
            <mat-slider
              aria-labelledby="setting-editor-font-size-label"
              min="6"
              max="48"
              step="1"
              showTickMarks
              color="primary"
            >
              <input aria-labelledby="setting-editor-font-size-label" matSliderThumb [(ngModel)]="editorFontSize" />
            </mat-slider>

            <span class="slider-value slider-value-fix-width">{{ editorFontSize }}</span>
          </div>
        </div>

        <div class="preference">
          <mat-label id="setting-editor-word-wrap-label">Quebra de linha</mat-label>

          <div class="preference-switch">
            <mat-slide-toggle
              aria-labelledby="setting-editor-word-wrap-label"
              [(ngModel)]="editorWordWrap"
              color="primary"
            >
              {{ editorWordWrap ? "Ativado" : "Desativado" }}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>

    <div class="preference-footer">
      <button type="button" mat-stroked-button (click)="resetDefaults()">Restaurar padrões</button>
    </div>
  </div>
</mat-dialog-content>

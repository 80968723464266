<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div class="sidebar">
  <button
    mat-ripple
    type="button"
    class="sidebar-button"
    (click)="runCode()"
    [disabled]="executor.running || transpiling"
    title="Iniciar execução"
  >
    <svg-icon src="assets/icon-start.svg" svgClass="text-secondary" svgAriaLabel="Ícone de iniciar execução" />
  </button>

  <button
    mat-ripple
    type="button"
    class="sidebar-button"
    (click)="stopCode()"
    [disabled]="!executor.running && !transpiling"
    title="Parar execução"
  >
    <svg-icon src="assets/mdi/square-rounded-outline.svg" svgClass="text-red" svgAriaLabel="Ícone de parar execução" />
  </button>

  <div class="sidebar-divider"></div>

  <button
    mat-ripple
    type="button"
    class="sidebar-button"
    (click)="saveFile()"
    title="Salvar arquivo"
    gaEvent="editor_save_file"
    gaCategory="editor"
    gaLabel="Botão de Salvar arquivo"
  >
    <svg-icon
      src="assets/mdi/content-save-outline.svg"
      svgClass="text-blue"
      svgAriaLabel="Ícone de disquete para ação de salvar arquivo"
    />
  </button>

  <button
    mat-ripple
    type="button"
    class="sidebar-button"
    title="Salvar como…"
    [matMenuTriggerFor]="saveMenu"
    gaEvent="editor_save_as"
    gaCategory="Editor"
    gaLabel="Botão de Salvar como"
  >
    <svg-icon
      src="assets/mdi/content-save-settings-outline.svg"
      svgClass="text-blue"
      svgAriaLabel="Ícone de disquete com três pontos abaixo para ação avançada de salvar arquivo"
    />
  </button>

  <mat-menu #saveMenu="matMenu">
    <button mat-menu-item disabled>Salvar como</button>

    @if (hasSaveFilePickerSupport) {
      <button
        mat-menu-item
        type="button"
        (click)="saveFileWithPicker()"
        aria-label="Escolher um arquivo"
        gaEvent="editor_save_as_file_picker"
        gaCategory="Editor"
        gaLabel="Botão de Salvar como - Ação Escolher um arquivo"
      >
        <mat-icon>
          <svg-icon
            src="assets/mdi/content-save-move.svg"
            svgAriaLabel="Ícone de disquete com seta para a direita para ação de escolher um arquivo"
          ></svg-icon>
        </mat-icon>

        Escolher um arquivo
      </button>
    }

    <button
      mat-menu-item
      type="button"
      (click)="saveFile()"
      aria-label="Download"
      gaEvent="editor_save_as_download"
      gaCategory="Editor"
      gaLabel="Botão de Salvar como - Ação Download"
    >
      <mat-icon>
        <svg-icon
          src="assets/mdi/download.svg"
          svgAriaLabel="Ícone de seta para baixo para ação de download do arquivo"
        ></svg-icon>
      </mat-icon>

      Download
    </button>

    <button
      mat-menu-item
      type="button"
      (click)="saveFile(true)"
      aria-label="Download para Portugol Studio"
      gaEvent="editor_save_as_download_portugol_studio"
      gaCategory="Editor"
      gaLabel="Botão de Salvar como - Ação Download para Portugol Studio"
    >
      <mat-icon>
        <svg-icon
          src="assets/lightbulb-sq.svg"
          svgAriaLabel="Ícone de seta para baixo para ação de download do arquivo"
        ></svg-icon>
      </mat-icon>

      Download para abrir no Portugol Studio
    </button>

    <button
      mat-menu-item
      type="button"
      (click)="saveFileManual('text')"
      aria-label="Abrir nova aba como texto"
      gaEvent="editor_save_as_text"
      gaCategory="Editor"
      gaLabel="Botão de Salvar como - Ação Nova aba como texto"
    >
      <mat-icon>
        <svg-icon
          src="assets/mdi/file-document-outline.svg"
          svgAriaLabel="Ícone de documento para ação de abrir nova aba como texto"
        ></svg-icon>
      </mat-icon>

      Abrir nova aba como texto
    </button>

    <button
      mat-menu-item
      type="button"
      (click)="saveFileManual('binary')"
      aria-label="Abrir nova aba como binário"
      gaEvent="editor_save_as_binary"
      gaCategory="Editor"
      gaLabel="Botão de Salvar como - Ação Nova aba como binário"
    >
      <mat-icon>
        <svg-icon
          src="assets/mdi/file-download-outline.svg"
          svgAriaLabel="Ícone de arquivo com seta para baixo para ação de abrir nova aba como binário"
        ></svg-icon>
      </mat-icon>

      Abrir nova aba como binário
    </button>
  </mat-menu>

  <button mat-ripple type="button" class="sidebar-button" (click)="fileInput.click()" title="Abrir arquivo">
    <svg-icon
      src="assets/mdi/folder-open-outline.svg"
      svgClass="text-primary"
      svgAriaLabel="Ícone de pasta para ação de abrir arquivo"
    />

    <input #fileInput type="file" hidden accept=".por,text/plain" (change)="openFile($event)" />
  </button>

  <button
    [disabled]="sharing"
    mat-ripple
    type="button"
    class="sidebar-button"
    (click)="shareFile()"
    title="Compartilhar código"
  >
    <svg-icon
      src="assets/mdi/share-variant-outline.svg"
      svgClass="text-secondary"
      svgAriaLabel="Ícone de compartilhar código"
    />
  </button>

  <div class="sidebar-divider"></div>

  <button mat-ripple type="button" class="sidebar-button" (click)="openHelp()" title="Abrir seção de Ajuda">
    <svg-icon src="assets/mdi/help-circle-outline.svg" svgClass="text-blue" svgAriaLabel="Ícone de ajuda" />
  </button>

  <s></s>

  <button
    mat-ripple
    type="button"
    class="sidebar-button"
    (click)="openSettings()"
    title="Configurações"
    gaEvent="editor_open_settings"
    gaCategory="Editor"
    gaLabel="Botão de Configurações"
  >
    <svg-icon src="assets/mdi/cog-outline.svg" svgClass="text-primary" svgAriaLabel="Ícone de engrenagem" />
  </button>
</div>

<div class="editor-inner">
  <as-split direction="vertical">
    <as-split-area [size]="80">
      <as-split direction="horizontal">
        <as-split-area [size]="100">
          <ngx-monaco-editor
            [options]="codeEditorOptions"
            [(ngModel)]="code"
            (init)="onEditorInit($event)"
          ></ngx-monaco-editor>
        </as-split-area>

        <as-split-area [size]="0">
          <ngx-monaco-editor [options]="generatedCodeEditorOptions" [(ngModel)]="executor.byteCode"></ngx-monaco-editor>
        </as-split-area>
      </as-split>
    </as-split-area>

    <as-split-area [size]="20">
      <ngx-monaco-editor
        id="output"
        [options]="stdOutEditorOptions"
        [(ngModel)]="executor.stdOut"
        (init)="onStdOutEditorInit($event)"
      ></ngx-monaco-editor>
    </as-split-area>
  </as-split>
</div>

<ng-template #shareSnackTemplate let-data>
  <div class="share-snack">
    <span class="share-snack-text">
      <span class="share-snack-link">
        <strong>Link gerado:</strong>
        <a target="_blank" [href]="data.url">
          {{ data.url | slice: 0 : 10 }}…{{ data.url | slice: data.url.length - 14 }}
        </a>
      </span>

      <small>
        <svg-icon src="assets/mdi/clock-alert.svg" svgClass="text-red" svgAriaLabel="Ícone de relógio" />
        Este link expira em 15 dias.
      </small>
    </span>

    <button mat-raised-button color="primary" type="button" (click)="copyStringAndCloseSnack(data.url)">COPIAR</button>
  </div>
</ng-template>

<as-split [direction]="isBelowMd ? 'vertical' : 'horizontal'" unit="pixel">
  <as-split-area [size]="350">
    <header>
      <svg-icon src="assets/mdi/help-circle-outline.svg" svgClass="text-blue" svgAriaLabel="Ícone de ajuda" />
      <span>Ajuda</span>
    </header>

    <ul id="root">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node">
          <li
            mat-ripple
            (click)="loadItem(node)"
            (keydown.enter)="loadItem(node)"
            [class.active]="current?.id === node.id"
            tabindex="0"
          >
            <button mat-icon-button disabled title="Ícone de pasta">
              <svg-icon />
            </button>
            <span>{{ node.text }}</span>
          </li>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
          <li
            (click)="loadItem(node)"
            (keydown.enter)="loadItem(node)"
            [class.active]="current?.id === node.id"
            tabindex="0"
          >
            <button
              mat-icon-button
              matTreeNodeToggle
              [ngSwitch]="treeControl.isExpanded(node)"
              aria-label="Expandir/retrair conteúdo da pasta"
            >
              <svg-icon
                *ngSwitchCase="true"
                src="assets/mdi/chevron-down.svg"
                svgAriaLabel="Ícone de seta para baixo indicando o conteúdo expandido"
              />

              <svg-icon
                *ngSwitchCase="false"
                src="assets/mdi/chevron-right.svg"
                svgAriaLabel="Ícone de seta para direita indicando o conteúdo recolhido"
              />
            </button>

            <span>{{ node.text }}</span>
          </li>

          <ul [class.hidden]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </mat-nested-tree-node>
      </mat-tree>
    </ul>
  </as-split-area>

  <as-split-area>
    <div class="help-content">
      @if (isLightTheme) {
        <div class="light-theme-alert">
          <svg-icon src="assets/mdi/emoticon-sad-outline.svg" svgAriaLabel="Ícone de rosto triste" />
          Sentimos muito! O conteúdo da seção Ajuda ainda não está disponível no tema claro.
        </div>
      }

      <iframe *ngIf="current" [src]="currentUrl"></iframe>
    </div>
  </as-split-area>
</as-split>

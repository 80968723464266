<header mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
  <span>{{ title }}</span>
  <s></s>
  <button tabindex="-1" type="button" mat-dialog-close mat-icon-button aria-label="Fechar diálogo">
    <svg-icon src="assets/mdi/close.svg" svgAriaLabel="Ícone de fechar diálogo" />
  </button>
</header>

<mat-dialog-content class="renderer-content">
  <canvas #canvas></canvas>
</mat-dialog-content>
